import Header from "../Header/Header";
import classes from "./Privacy-policy.module.css";
import policyImg from "./../../img/ShieldCheck.svg";
import { Link } from "react-router-dom";
const PrivacyPolicy = () => {
  return (
    <div className={classes.verifyTop}>
      <Header />
      <div className={classes.mainContent}>
        <div className={classes.headerPolicy}>
          <img src={policyImg} alt="policyImg" />
          <h2>Privacy Policy</h2>
          <br/>
        </div>

        <h3><b>Effective Date:</b> January 2024</h3>
        <h3>Introduction</h3>
        <p>
          This Privacy Policy details how Tektools("we," "us," or "our") manages personal information collected through our digital services linked to this policy, including our website. We are committed to protecting your privacy and ensuring transparency in our data handling practices.
        </p>
        <br/>
        <h3>Information We Collect</h3>
        <h4>Information You Provide to Us:</h4>
        <ul>
          <li>Contact Data: Your name, email address, and both billing and mailing addresses.</li>
          <li>Profile Data: Username, password, display name, profile photo, and links to your social media profiles.</li>
          <li>Communications: Records of your communications with us via the service, social media, or email.</li>
          <li>Participation Data: Your interactions within our services, such as votes, proposals, and community participation.</li>
          <li>Transactional Data: Details of transactions you carry out through our services, including any virtual currency or wallet details.</li>
        </ul>
        <h4>Information from Third-party Sources:</h4>
        <ul>
          <li>Public Sources: Such as public blockchains and social media platforms.</li>
          <li>Data Providers: Information services that provide demographic and other data.</li>
          <li>Marketing Partners: Information from our joint marketing partners and event co-sponsors.</li>
        </ul>
        <h4>Automatically Collected Information:</h4>
        <ul>
          <li>Device and Usage Data: Includes details about your device and your interactions with our service.</li>
          <li>Location Data: Collected when you enable device location services.</li>
        </ul>
        <h3>Cookies and Tracking Technologies</h3>
        <p>
          We use cookies, local storage technologies, and web beacons to facilitate the technical operation of our services, enhance functionality, and conduct analytics. You can manage cookies through your browser settings.
        </p>
        <h3>How We Use Your Information</h3>
        <ul>
          <li>Service Delivery: To manage your account, provide our services, and communicate with you.</li>
          <li>Research and Development: To analyze and improve our services.</li>
          <li>Marketing: To send you promotional messages and personalized offers. You can opt-out of marketing communications at any time.</li>
          <li>Compliance and Protection: To comply with laws, respond to legal requirements, and protect our rights.</li>
        </ul>
        <h3>Sharing Your Information</h3>
        <p>
          We share your information with affiliates, service providers, business partners, legal authorities, and in connection with business transfers. We ensure these parties respect your privacy in line with this policy.
        </p>
        <h3>Your Rights and Choices</h3>
        <ul>
          <li>Access and Control: You can review, update, or delete your personal information through your account settings.</li>
          <li>Opt-out of Cookies: Adjust your browser settings to decline cookies if you prefer.</li>
          <li>Marketing Opt-outs: You can unsubscribe from marketing emails via the unsubscribe link or by contacting us directly.</li>
        </ul>
        <h3>International Data Transfers</h3>
        <p>
          Your information may be transferred to, stored, and processed in the United States or other countries. We take measures to ensure your information is protected adequately, as required by applicable law.
        </p>
        <h3>Security Measures</h3>
        <p>
          We implement technical, physical, and organizational measures to protect your personal information from unauthorized access, use, or disclosure.
        </p>
        <h3>Children’s Privacy</h3>
        <p>
          Our services are not intended for children under 16 years old. We do not knowingly collect information from children under this age.
        </p>
        <h3>Changes to This Privacy Policy</h3>
        <p>
          We may update this policy from time to time. We will notify you of any significant changes by updating the policy on our website and specifying the effective date of the new version.
        </p>
        <h3>Contact Us</h3>
        <p>
          If you have any questions about this Privacy Policy or our privacy practices, please contact us at: support@tektools.app.
        </p>
      </div>

      <div className={classes.footer}>
        <p>
          © 2024. All rights reserved by{" "}
          <a href="https://tektools.app">Tektools.</a>
        </p>
        <ul>
          <li>
            <Link to="/privacy-policy">
              Privacy Policy
            </Link>
          </li>
          <li>
            <Link to="/terms-condition">Terms of Use</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
