import Header from "../Header/Header";
import classes from "./VerifyWallet.module.css";
import heroLeft from "./../../img/heroLeftImg.png";
import heroRight from "./../../img/heroRightImg.png";
import profile from "./../../img/profile.png";
import { useState, useEffect, useRef } from "react";
import { FaDiscord } from "react-icons/fa";
import axios from "axios";
import Logo from "../Header/logo";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import * as buffer from "buffer";

import { Toggle } from "rsuite";
import "rsuite/dist/rsuite.css";

import {
  Connection,
  Keypair,
  SystemProgram,
  LAMPORTS_PER_SOL,
  Transaction,
  sendAndConfirmTransaction,
  TransactionSignature,
} from "@solana/web3.js";
import { Link } from "react-router-dom";
// import { log } from "console";

window.Buffer = buffer.Buffer;

const apiUrl = process.env.REACT_APP_BACKEND_API;

const VerifyWallet = () => {
  const [loginDiscord, setIsLoginDiscord] = useState(null);
  const [signedMessage, setSignedMessage] = useState(null);
  // const [userInfo, setUserInfo] = useState(null);
  const [userGuilds, setUserGuilds] = useState(null);
  const [walletVerifiedMessage, setIsWalletVerifiedMessage] = useState(false);
  const { connected, signMessage, publicKey, sendTransaction } = useWallet();
  const [data, setData] = useState(null);
  const [isLedger, setIsLedger] = useState(false);
  let { connection } = useConnection();

  const logged = useRef(false);

  // useEffect(() => {
  //   if (!connected) setStatus(null);
  // }, [connected]);

  // useEffect(() => {
  //   if (!loginDiscord) setIsLoginDiscord(null);
  // }, [loginDiscord]);

  // useEffect(() => {
  //   if (!userGuilds) setUserGuilds(null);
  // }, [userGuilds]);

  useEffect(() => {
    if (logged.current === false) {
      validateDiscordUser();
      logged.current = true;
    }
  }, []);

  const validateDiscordUser = async () => {
    const fragment = new URLSearchParams(window.location.hash.slice(1));
    const [accessToken, tokenType] = [
      fragment.get("access_token"),
      fragment.get("token_type"),
    ];
    //console.log(accessToken);
    //let userInfo = {};
    if (!accessToken && !tokenType) return;
    // console.log('callind discord api');

    const userResponse = await axios.get("https://discord.com/api/users/@me", {
      headers: {
        Authorization: `${tokenType} ${accessToken}`,
      },
    });

    setIsLoginDiscord(userResponse);

    //get users guild
    const guildsResponse = await axios.get(
      "https://discord.com/api/users/@me/guilds",
      {
        headers: {
          Authorization: `${tokenType} ${accessToken}`,
        },
      }
    );
    setUserGuilds(guildsResponse);
  };

  const sign = async (retries = 3) => {
    if (!connected) return;
    const signCode = process.env.REACT_APP_Sign_Code;
    const url = apiUrl + "/Sign?code=" + signCode;

    try {
      const dataNew = await getMessage();

      //console.log(data)
      //console.log(dataNew)
      //if (!data) { return 'sorry an error happened getting message.' }
      const nonceEncoded = new TextEncoder().encode(
        btoa(JSON.stringify(dataNew.data))
      );
      const signed = await signMessage(nonceEncoded);
      const res = await axios({
        method: "post",
        url: url,
        data: {
          signature: toHexString(signed),
          publicKey: publicKey,
          data: dataNew,
          userInfo: loginDiscord.data,
          userGuilds: userGuilds.data,
        },
      });
      //console.log(res);
      setSignedMessage({
        status: "success",
        message: res.data,
      });
    } catch (error) {
      //console.log(`error ${error}, retries ${retries}`);
      if (retries > 0) {
        return sign(retries - 1);
      }
      logError(error, url);
      setSignedMessage({
        status: "error",
        message: error.response?.data,
      });
      //TODO: LOG TO DISCORD
    }
  };

  const ledgerSignin = async (retries = 3) => {
    if (!connected) return;
    const signCode = process.env.REACT_APP_Sign_Code;
    const url = apiUrl + "/Sign?code=" + signCode;

    try {
      setSignedMessage({
        status: "pending",
        message: "Starting Ledger process... please follow your wallet instructions and don't close or refresh this window.",
      });
      setTimeout(() => {
        setSignedMessage({
          status: "pending",
          message: "Please wait...",
        });
      }, 18000);
      const dataNew = await getMessage();
      const trx = await ledgerTransaction();
      const res = await axios({
        method: "post",
        url: url,
        data: {
          signature: trx,
          publicKey: publicKey,
          data: dataNew,
          userInfo: loginDiscord.data,
          userGuilds: userGuilds.data,
          isLedger: true,
        },
      });
      setSignedMessage({
        status: "success",
        message: res.data,
      });
    } catch (error) {
      //console.log(`error ${error}`);
      logError(error, url);
      setSignedMessage({
        status: "error",
        message: "Sorry an error happened during this operation.",
      });
    }
  };

  const getMessage = async () => {
    const messageCode = process.env.REACT_APP_Message_Code;
    // setData(null)
    const result = await axios({
      method: "post",
      url:
        apiUrl +
        "/GetMessage?code=" + messageCode,
      data: {
        publicKey: publicKey,
      },
    });
    // console.log(`data from api ${result.data}`)
    setData({
      data: result.data,
    });

    return result;
  };

  const ledgerTransaction = async () => {
    try {
      const rpcUrl = process.env.REACT_APP_RPC_URL;
      connection = new Connection(rpcUrl, "confirmed");

      const lamportsToSend = 1_000_0;
      let signature = "";
      const transaction = new Transaction().add(
        SystemProgram.transfer({
          fromPubkey: publicKey,
          toPubkey: publicKey,
          lamports: lamportsToSend,
        })
      );
      // console.log(connection);
      signature = await sendTransaction(transaction, connection);

      await connection.confirmTransaction(signature, "confirmed");

      //console.log(signature);
      return signature;
    } catch (error) {
      setSignedMessage({
        status: "error",
        message: "Sorry, a network error happened processing this transaction.",
      });
      logError(error, "");

    }
  };

  const toHexString = (buffer) => {
    return buffer.reduce(
      (str, byte) => str + byte.toString(16).padStart(2, "0"),
      ""
    );
  };

  const logError = async (error, url) => {
    // var myEmbed = {
    //   author: {
    //     name: "Captain Hook"
    //   },
    //   title: "My new embed",
    //   description: "This is a cool-looking Discord embed, sent directly from JavaScript!",
    //   color: hexToDecimal("#ff0000")
    // }
    // var params = {
    //   username: "My Webhook Name",
    //   embeds: [ myEmbed ]
    // }

    const params = {
      username: "Error signing message",
      avatar_url: "https://toolboxnftstorageprod.blob.core.windows.net/toolboximages/TektoolsFaceWhite.png",
      content: "\nerror: " + error + // "\nerror stack: " + error.stack +
        " \npublicKey: " + publicKey + " \nloginDiscordId: " + loginDiscord.data.id + " \nloginDiscordUsername: " + loginDiscord.data.username +
        " \nisLedger: " + isLedger +
        " \napiUrl: " + url,

    }
    const params2 = {
      "username": "Error signing message",
      "avatar_url": "https://toolboxnftstorageprod.blob.core.windows.net/toolboximages/TektoolsFaceWhite.png",
      // "content": "Text message. Up to 2000 characters.",
      "embeds": [
        {

          "title": "Error signing message",
          "description": "\nerror: " + error + // "\nerror stack: " + error.stack +
            " \npublicKey: " + publicKey + " \nloginDiscordId: " + loginDiscord.data.id + " \nloginDiscordUsername: " + loginDiscord.data.username +
            " \nisLedger: " + isLedger +
            " \napiUrl: " + url,
          "color": 14177041,

          // "thumbnail": {
          //   "url": "https://upload.wikimedia.org/wikipedia/commons/3/38/4-Nature-Wallpapers-2014-1_ukaavUI.jpg"
          // },
          // "image": {
          //   "url": "https://upload.wikimedia.org/wikipedia/commons/5/5a/A_picture_from_China_every_day_108.jpg"
          // },
          // "footer": {
          //   "text": "Woah! So cool! :smirk:",
          //   "icon_url": "https://i.imgur.com/fKL31aD.jpg"
          // }
        }
      ]
    }

    const res = await axios({
      method: "post",
      url: "https://discord.com/api/webhooks/1161032670550163628/U8TEgcavARjEh76F6jysg_2mxB9uWxkFMMbqgRAjLm0mIEdfokJZGnVjiIPGNlLwDDQ5",
      data: params2,
    });

    function hexToDecimal(hex) {
      return parseInt(hex.replace("#", ""), 16)
    }
  }

  return (
    <>
      <div className={classes.verifyTop}>
        <img className={classes.imgLeft} src={heroLeft} alt="heroLeft" />
        <img className={classes.imgRight} src={heroRight} alt="heroRight" />
        <Header />
        <div className={classes.heroTop}>
          <div className={classes.innerContent}>
            <h1>Tektools</h1>
            <p>Wallet Verification</p>
            {!loginDiscord && (
              <div className={classes.btns}>
                <a
                  className="tbd"
                  href={process.env.REACT_APP_DISCORD_AUTH_URL}
                >
                  <FaDiscord className="fancy-link__icon" /> Login with Discord
                </a>
              </div>
            )}
          </div>
          {/* discord login here ============= */}
          {loginDiscord && (
            <div className={classes.discordLogin}>
              <div className={classes.profilePic}>
                <img
                  src={`https://cdn.discordapp.com/avatars/${loginDiscord.data.id}/${loginDiscord.data.avatar}.jpg`}
                  id="avatar"
                  alt="profile"
                />
                <p>{loginDiscord.data.username}</p>
              </div>
              {!publicKey && <h1>Connect wallet to start</h1>}
            </div>
          )}

          {/* your wallet is verify  ====== */}
          {loginDiscord && (
            <div className={classes.toggle}>
              <label>
                <h5>Ledger Wallet?</h5>
              </label>
              <Toggle
                size="lg"
                checkedChildren="Yes"
                unCheckedChildren="No"
                onChange={(value) => {
                  setIsLedger(value);
                }}
              />
            </div>
          )}
          {isLedger === true ? (
            <button className={classes.signMessage} onClick={ledgerSignin}>
              Sign Ledger Transaction
            </button>
          ) : (
            <>
              {loginDiscord && publicKey && !signedMessage && (
                <button className={classes.signMessage} onClick={sign}>
                  Sign Message
                </button>
              )}
            </>
          )}

          {signedMessage && publicKey && (
            <div className={classes.walletVerified}>
              <h3>
                {/* Your Wallet is verified, you can safely close this window now.{" "} */}
                {signedMessage.message}
              </h3>
            </div>
          )}
          {/* sign message ============== */}
        </div>

        <div className={classes.footer}>
          <p>
            © 2024. All rights reserved by{" "}
            <a href="https://tektools.app">Tektools.</a>
          </p>
          <ul>
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/terms-condition">Terms of Use</Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
export default VerifyWallet;
