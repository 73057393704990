import Header from "../Header/Header";
import classes from "./Terms-condition.module.css";
import policyImg from "./../../img/ShieldCheck.svg";
import termsConditionImg from './../../img/ListBullets.svg';
import { Link } from "react-router-dom";
const TermsCondition = () => {
  return (
    <div className={classes.verifyTop}>
      <Header />
      <div className={classes.mainContent}>
        <div className={classes.headerPolicy}>
          <img src={termsConditionImg} alt="termsConditionImg" />
          <h2>Terms and Conditions</h2>
        </div>

        <h3>Effective Date: January 2024</h3>
        <p>
          <h4>1. Introduction</h4>
          <br />
          Welcome to Tektools, designed to enhance functionality and security for web3 servers on Discord. This document outlines the terms under which you may use our services.
        </p>

        <p>
          <h4>2. Acceptance of Terms</h4>
          <br />
          By using Tektools, you accept these Terms and Conditions and any amendments. Server owners are responsible for informing their community members about these terms.
        </p>

        <p>
          <h4>3. Services Offered</h4>
          <br />
          Tektools provides security features, utilities like pools and giveaways, NFT-related notifications, games, and role management based on public wallet addresses.
        </p>

        <p>
          <h4>4. User Data</h4>
          <br />
          We collect minimal data such as user ID, username, avatar URLs, and public wallet addresses, solely for enhancing service functionality. Data is stored indefinitely under strict security protocols.
        </p>

        <p>
          <h4>5. User Responsibilities</h4>
          <br />
          Users must interact with Tektools responsibly, adhering to its intended use without attempting to compromise its functionality or security.
        </p>

        <p>
          <h4>6. Paid Services</h4>
          <br />
          Tektools offers both free and premium tiers, with the latter providing additional functionalities subject to the payment terms agreed upon during subscription.
        </p>

        <p>
          <h4>7. Modifications to Terms</h4>
          <br />
          Continued use of Tektools after changes have been posted will be regarded as acceptance of those changes.
        </p>

        <p>
          <h4>8. Data Management</h4>
          <br />
          Users may request data deletion by contacting us directly, and all user data will be securely deleted upon request.
        </p>

        <p>
          <h4>9. Dispute Resolution</h4>
          <br />
          Disputes arising from the use of our services should be resolved through mediation by an agreed-upon mediator, conducted in [Specify Location] or virtually.
        </p>

        <p>
          <h4>10. Class Action Waiver</h4>
          <br />
          You waive any right to participate in a class action lawsuit or class-wide arbitration against us.
        </p>

        <p>
          <h4>11. Termination and Suspension</h4>
          <br />
          We reserve the right to terminate or suspend access to our services for users who violate these terms.
        </p>

        <p>
          <h4>12. Disclaimer of Warranties and Limitation of Liability</h4>
          <br />
          Tektools is provided "as is" without warranties of any kind. Liability is limited to the fullest extent permitted by law.
        </p>
      </div>

      <div className={classes.footer}>
        <p>
          © 2024. All rights reserved by{" "}
          <a href="https://tektools.app">Tektools.</a>
        </p>
        <ul>
          <li>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/terms-condition">Terms of Use</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default TermsCondition;
